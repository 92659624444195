import React, { useState } from 'react'
import styled from 'styled-components'

import Checkbox from './Checkbox'
import Modal from './Modal'


const ObjectiveInline = props => {
  const [showSuccess, setShowSuccess] = useState(false);

  const toggle = () => {
    if (!props.completed) {
      setShowSuccess(true)
    }
    props.toggle(props.objective)
  }

  return <div id={props.objective.handle} className={props.className}>
    <div className="objectiveInline">
      <div className="objectiveInline__title fontHeading">
        {(props.objective.order ? props.objective.order + '. ' : '') + props.objective.title}
      </div>
      <Checkbox className="ml-3 flex-shrink-0" checked={props.completed} onClick={toggle} notTabbable={!props.available} />
    </div>

    <Modal
      isOpen={showSuccess}
      close={() => setShowSuccess(false)}
      objectiveTitle={props.objective.title}
      objectiveSubTitle={'Complete'}
      widthMode={props.objective.fullWidth ? 'fullWidth' : null}
      setDoubleModal={props.setDoubleModal}
    >
      {props.objective.success}
    </Modal>
  </div>
}

const StyledObjectiveInline = styled(ObjectiveInline)`
  .objectiveInline {
    display: flex;
    align-items: center;

    &__title {
      flex: 1 1 auto;
      text-align: left;
      font-size: 20rem;
    }
  }
`

export default StyledObjectiveInline
