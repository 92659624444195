import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import backgroundDark from '../images/background-dark.png'


const Modal = props => {
  const { isOpen, isInBackground } = props

  const modalOuter = useRef(null)

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.close()
    }
  }

  useEffect(() => {
    if (isOpen && !isInBackground) {
      document.addEventListener("keydown", escFunction, false)
      modalOuter.current.focus();
    } else {
      document.removeEventListener("keydown", escFunction, false)
    }
    return () => {
      document.removeEventListener("keydown", escFunction, false) // failsafe, just in case the component unmounts
    }
  }, [isOpen, isInBackground])

  useEffect(() => {
    if (props.setDoubleModal) {
      if (props.isOpen) {
        props.setDoubleModal(true)
      } else {
        props.setDoubleModal(false)
      }
    }
    return () => {
      if (props.setDoubleModal) {
        props.setDoubleModal(false) // failsafe, just in case the component unmounts
      }
    }
  }, [isOpen])

  const handleCloseKeyPress = event => {
    if (
      event.keyCode === 13 || // enter
      event.keyCode === 32 // space
    ) {
      event.preventDefault()
      props.close()
    }
  }

  return props.isOpen ?
    <div className={props.className} tabIndex="-1" ref={modalOuter}>
      <div className="modal__overlay" onClick={props.close} role="button"></div>
      <div className={
        'modal'
        + (props.widthMode ? ' modal--' + props.widthMode : '')
      }>
        <div
          className="modal__close"
          onClick={props.close}
          aria-label="Close"
          tabIndex="0"
          onKeyDown={handleCloseKeyPress}
          role="button"
        />
        {props.title ?
          <div className="modal__title fontHeading">
            {props.title}
            {props.subTitle ? <div className="modal__subTitle">{props.subTitle}</div> : null}
          </div>
        : null}
        {props.objectiveTitle ?
          <div className="modal__objectiveTitle fontHeading">
            {props.objectiveTitle}
            {props.objectiveSubTitle ? <div className="modal__objectiveSubTitle">{props.objectiveSubTitle}</div> : null}
          </div>
        : null}
        <div className="modal__body">
          {props.children}
        </div>
      </div>
    </div>
  : null
}

const StyledModal = styled(Modal)`
  --padding: 40px;
  --boxShadow: 9px;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  .modal__overlay {
    position: absolute;
    top: -100vh;
    left: -100vw;
    right: -100vw;
    bottom: -100vh;
    background: var(--overlay);
  }

  .modal {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 50vw;
    width: max-content;
    max-width: 90vw;
    max-height: 80vh;
    transform: translate(-50%, -50%);
    padding: var(--padding) var(--padding) calc(var(--padding) + 8px);
    text-align: center;
    border-radius: 40px;
    box-shadow:
      4px 0 0 0 rgba(255, 251, 205, 0.18) inset,
      0 4px 0 0 rgba(255, 251, 205, 0.18) inset,
      -4px 0 0 0 rgba(255, 251, 205, 0.18) inset,
      0 -4px 0 0 rgba(255, 251, 205, 0.18) inset,
      var(--boxShadow) 0 0 0 rgba(0, 0, 0, 0.3) inset,
      0 var(--boxShadow) 0 0 rgba(0, 0, 0, 0.3) inset,
      calc(var(--boxShadow) * -1) 0 0 0 rgba(0, 0, 0, 0.3) inset,
      0 calc(var(--boxShadow) * -1) 0 0 rgba(0, 0, 0, 0.3) inset
      ${props => props.colour ? `, 0 0 80px 0 ${props.colour} inset` : ''};
    background: url(${backgroundDark}) center;

    @media screen and (max-width: 640px) {
      width: 90vw;
    }

    &.modal--naturalWidth {
      width: auto;
      min-width: 20vw;
      > .modal__body {
        width: max-content;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &.modal--fullWidth {
      width: 90vw;
    }

    &.modal--fullContents {
      min-width: 50vw;
      min-height: 30vh;
      padding-bottom: var(--padding);
      > .modal__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: auto;
        overflow: visible;
      }
    }
  }

  .modal__title {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
    font-size: 40rem;
    text-align: center;
  }

  .modal__objectiveTitle {
    position: relative;
    padding: 6px calc(var(--padding) - var(--boxShadow));
    background: rgba(255, 254, 246, 0.2);
    margin-left: calc(var(--boxShadow) - var(--padding));
    margin-right: calc(var(--boxShadow) - var(--padding));
    margin-bottom: var(--padding);
    font-size: 40rem;
    font-style: italic;
    text-align: center;
    color: var(--blackText);

    .modal__objectiveSubTitle {
      position: absolute;
      right: 8%;
      bottom: -0.5em;
      font-size: 32rem;
      font-style: normal;
      color: var(--yellowText);
      text-shadow: 0 0 0.5em #f4c50d;
    }
  }

  .modal__body {
    box-sizing: content-box;
    width: 1px;
    min-width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    margin: -12px;
    padding: 12px;

    > * + *, > *:only-child:not(.dialogueBox) > * + * {
      margin-top: var(--contentSpacing);
    }
    > * + .dialogueBox {
      margin-top: calc(var(--contentSpacing) + var(--dialogueBoxMarginTop));
    }
  }

  .modal__close {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background: var(--primaryBg);
    border: 1px solid var(--whiteText);
    border-radius: 50%;
    cursor: pointer;

    &:focus {
      box-shadow: var(--focusBoxShadow);
    }

    &:before, &:after {
      content: "";
      position: absolute;
      width: 20px;
      height: 3.5px;
      background: var(--yellowText);
      border-radius: 2px;
      top: 50%;
      left: 50%;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
`

export default StyledModal
