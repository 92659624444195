import React, { useState } from 'react'
import styled from 'styled-components'

import Button from './Button'
import Modal from './Modal'

const InventoryPage = props => {
  const [open, setOpen] = useState(false);

  return <div className={props.className}>
    <Button onClick={() => setOpen(true)}>{props.name}</Button>
    <Modal isOpen={open} isInBackground={props.isInBackground} close={() => setOpen(false)} title={props.name} widthMode="naturalWidth">
      {props.children}
    </Modal>
  </div>
}


const StyledInventoryPage = styled(InventoryPage)`
`

export default StyledInventoryPage
