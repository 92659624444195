import React from 'react'
import styled from 'styled-components'

import Accordion from './Accordion'


const About = props => {
  return <div className={props.className}>
    <Accordion title="About" expandedByDefault={props.expandedByDefault}>
      <p>If you’ve ever wished that Breath of the Wild was a little more like the other Legend of Zelda games, or if you’re just looking for a fresh way to enjoy it, this website provides instructions for playing the game in a way that captures the feel of previous entries in the series.</p>
      <p>You can also use the website to track your progress so that you can see at a glance where to head next and what abilities are currently available to you.</p>
    </Accordion>
  </div>
}

const StyledAbout = styled(About)`
`

export default StyledAbout
