import React from 'react'
import styled from 'styled-components'

import Accordion from './Accordion'


const HowToUse = props => {
  return <div className={props.className}>
    <Accordion title="How to Use" expandedByDefault={props.expandedByDefault}>
      <p>First, read through the <em>Restrictions</em> section so that you know what is and isn’t allowed while playing the game.</p>

      <p>Then, use the <em>Objectives</em> list below to see where to go next, tracking your progress by <strong>ticking the checkbox</strong> for each objective you complete. When you complete an objective that unlocks an ability, that ability will become available in your <em>Inventory</em> at the bottom of the screen, indicating that you can now use it.</p>

      <p>The website will <strong>automatically save your progress</strong> in your browser’s local storage (browser settings permitting) so that you can pick up where you left off between sessions.</p>

      <p>Finally, don’t forget to keep an eye on the <em>Side Quests</em>. Side quests intentionally only reveal their shrine's name. In doing so, they don’t give away where you need to go before they’re hinted at in an objective’s success message, but you’ll still know to mark them as complete if you stumble across them early.</p>
    </Accordion>
  </div>
}

const StyledHowToUse = styled(HowToUse)`
`

export default StyledHowToUse
