import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons'

import Modal from './Modal'


const InventoryItem = props => {
  const [open, setOpen] = useState(false);

  const handleKeyPress = event => {
    if (
      event.keyCode === 13 || // enter
      event.keyCode === 32 // space
    ) {
      event.preventDefault()
      setOpen(true)
    }
  }
  
  return <>
    <div
      className={props.className + ' ' + props.handle + (props.available ? '' : ' locked')}
      style={{ backgroundImage: 'url(' + props.image + ')' }}
      onClick={() => setOpen(true)}
      tabIndex="0"
      onKeyDown={handleKeyPress}
      role="button"
    >
      <div className="name">{props.name}</div>
      {props.available ? null : <FontAwesomeIcon className="inventoryItem__locked" icon={faBan} />}
    </div>
    <Modal isOpen={open} close={() => setOpen(false)} title={props.name} colour={props.colour}>
      {props.available ? (
        <>
          <p>This ability has been unlocked.</p>
          <FontAwesomeIcon className="statusIcon" icon={faCheck} />
          {props.availableContent}
        </>
      ) : (
        <>
          <p>This ability is currently locked.</p>
          <FontAwesomeIcon className="statusIcon statusIcon--locked" icon={faBan} />
          {props.lockedContent}
        </>
      )}
    </Modal>
  </>
}

const StyledInventoryItem = styled(InventoryItem)`
  background: ${props => props.available ? props.colour : 'var(--silver)'};
  overflow: hidden;
  position: relative;
  height: 0;
  padding-bottom: 100%;
  background-size: contain;
  border: solid ${props => props.available ? '1px #404040' : '1px rgba(235, 13, 73, 0.5)'};
  box-shadow:
    1px 1px 1px 0 rgba(255, 255, 255, 0.2) inset,
    -1px -1px 1px 0 rgba(0, 0, 0, 0.2) inset,
    5px 5px 3px -3px rgba(255, 255, 255, 0.4) inset,
    -5px -5px 3px -3px rgba(0, 0, 0, 0.6) inset
    ${props => props.available ? '' : ', 0 0 4px 1.5px rgba(235, 13, 73, 0.7) inset'};
  cursor: pointer;

  &:focus {
    box-shadow: var(--focusBoxShadow),
    1px 1px 1px 0 rgba(255, 255, 255, 0.2) inset,
    -1px -1px 1px 0 rgba(0, 0, 0, 0.2) inset,
    5px 5px 3px -3px rgba(255, 255, 255, 0.4) inset,
    -5px -5px 3px -3px rgba(0, 0, 0, 0.6) inset
    ${props => props.available ? '' : ', 0 0 4px 1.5px rgba(235, 13, 73, 0.7) inset'};
  }

  .name {
    position: absolute;
    bottom: 3px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 0 3px;
    text-align: center;
    z-index: 2;
    font-size: 6px;
    font-weight: bold;
    color: ${props => props.available ? 'var(--whiteText)' : '#cccccc'};
    text-transform: uppercase;
    text-shadow:
      -1px -1px 0 var(--blackText),
      -1px 0 0 var(--blackText),
      -1px 1px 0 var(--blackText),
      0 -1px 0 var(--blackText),
      0 1px 0 var(--blackText),
      1px -1px 0 var(--blackText),
      1px 0 0 var(--blackText),
      1px 1px 0 var(--blackText),
      0 0 2px var(--blackText);
    @media screen and (min-width: 500px) {
      font-size: 7px;
    }
    @media screen and (min-width: 610px) {
      font-size: 8px;
    }
    @media screen and (min-width: 1390px) {
      font-size: 9px;
    }
  }

  .inventoryItem__locked.inventoryItem__locked {
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    width: auto;
    height: auto;
    color: ${props => props.colour};
    z-index: 1;
    opacity: 0.5;

    path {
      stroke: black;
      stroke-width: 8;
    }
  }
`

export default StyledInventoryItem
