import React from 'react'
import styled from 'styled-components'

import ObjectiveCard from './ObjectiveCard'
import ObjectiveInline from './ObjectiveInline'

const ObjectiveList = props => {
  const isAvailable = order => {
    if (props.showAll || order <= 1) {
      return true
    }
    let previous = props.objectives.find(objective => objective.order === order - 1).handle
    return previous ? props.completed[previous] : false
  }

  return <div className={props.className}>
    {props.compact ? (
      props.objectives.map((objective, index) => (
        <ObjectiveInline
          key={index}
          objective={objective}
          available={isAvailable(objective.order)}
          completed={props.completed[objective.handle]}
          toggle={props.toggleObjective}
          setDoubleModal={props.setDoubleModal}
        />
      ))
    ) : (
      props.objectives.map((objective, index) => (
        <ObjectiveCard
          key={index}
          objective={objective}
          available={isAvailable(objective.order)}
          completed={props.completed[objective.handle]}
          toggle={props.toggleObjective}
        />
      ))
    )}
  </div>
}

const StyledObjectiveList = styled(ObjectiveList)`
  
`

export default StyledObjectiveList
