import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { objectives } from '../objective-data'
import ogImage from '../images/botw-traditional-quest.jpg'
import twitterImage from '../images/botw-traditional-quest-2x1.jpg'
import Container from '../components/Container'
import Inventory from '../components/Inventory'
import About from '../components/About'
import HowToUse from '../components/HowToUse'
import Restrictions from '../components/Restrictions'
import Tips from '../components/Tips'
import ObjectiveList from '../components/ObjectiveList'
import Footer from '../components/Footer'


const IndexPage = () => {
  const [completed, setCompleted] = useState({});
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    // get progress from localStorage on page load
    if (typeof window !== 'undefined' && localStorage.getItem('progress')) {
      setCompleted(JSON.parse(localStorage.getItem('progress')))
    }
    // This should scroll the next objective into view on page load but seems to be hard to test in dev mode
    if (completed.tower) {
      for (let i = 2; i < 33; i++) {
        let objective = objectives.find(o => o.order === i)
        if (!completed[objective.handle]) {
          document.getElementById(objective.handle).scrollIntoView()
          break
        }
      }
    }
  }, [])

  useEffect(() => {
    // so long as completed isn't an empty object, update localStorage every time it changes
    if (typeof window !== 'undefined' && Object.keys(completed).length > 0) {
      localStorage.setItem('progress', JSON.stringify(completed))
    }
  }, [completed])

  const toggleObjective = objective => {
    // if the objective is a side quest, simply toggle it and leave all other objectives alone
    if (objective.order === 0) {
      setCompleted({...completed, [objective.handle]: !completed[objective.handle]})
      return
    }
    // establish whether this is a tick or an untick using `handle` and `completed`
    let wasATick = !completed[objective.handle]
    // create array of items from `objectives` whose `order` is less (tick) or greater (untick) than `order`
    let toChange = objectives.filter(item => {
      return wasATick
        ? item.order > 0 && item.order <= objective.order
        : item.order >= objective.order
    })
    // loop through the array and tick or untick each one (including the current one)
    let changes = {}
    toChange.forEach(item => {
      changes[item.handle] = wasATick
    })
    setCompleted({...completed, ...changes})
  }

  const toggleShowAll = () => {
    setShowAll(!showAll)
  }

  const url = 'https://botw.lineagegaming.co.uk'
  const title = 'Play Breath of the Wild like a traditional Zelda game'
  const desc = 'Looking for a new way to play Breath of the Wild? This tool guides you through the game using restrictions that make it feel like the older Zelda titles.'

  return (
    <main>
      <Helmet>
        <link rel="canonical" href={url} />
        <title>{title}</title>
        <meta name="Description" CONTENT={desc} />

        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#c3b08a" />
        <meta name="apple-mobile-web-app-title" content="Traditional Quest" />
        <meta name="application-name" content="Traditional Quest" />
        <meta name="msapplication-TileColor" content="#2b5797" />
        <meta name="theme-color" content="#2c2c2c" />

        <meta property="og:url"                content={url} />
        <meta property="og:type"               content="website" />
        <meta property="og:title"              content={title} />
        <meta property="og:description"        content={desc} />
        <meta property="og:image"              content={url + ogImage} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Lineage_Games" />
        <meta name="twitter:creator" content="@Lineage_Games" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={desc} />
        <meta name="twitter:image" content={url + twitterImage} />
        <meta name="twitter:image:alt" content="The Breath of the Wild Traditional Quest running on a smartphone next to Joy-Con controllers" />
      </Helmet>
      
      <h1 className="mainTitle fontTitle mt-3 mb-1 mx-3"><small>Breath of th<span className="letterSpacingFix">e</span> Wild</small><br />Traditional Quest</h1>
      <h2 className="text-center mb-5 mx-3">by <a href="http://lineagegaming.co.uk" target="_blank">Lineage Gaming</a></h2>
      <Container>
        <About expandedByDefault={!completed.tower} />
        <HowToUse expandedByDefault={!completed.tower} />
        <Restrictions expandedByDefault={!completed.tower} />
        <Tips expandedByDefault={!completed.tower} />
        <ObjectiveList completed={completed} toggleObjective={toggleObjective} objectives={objectives} showAll={showAll} />
      </Container>

      <Footer />

      <Inventory completed={completed} toggleObjective={toggleObjective} toggleShowAll={toggleShowAll} showAll={showAll} />
    </main>
  )
}

export default IndexPage
