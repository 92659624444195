import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const Checkbox = props => {
  const handleKeyPress = event => {
    if (
      event.keyCode === 13 || // enter
      event.keyCode === 32 // space
    ) {
      event.preventDefault()
      props.onClick()
    }
  }

  return <div
    className={props.className + ' actionBox'}
    onClick={props.onClick}
    tabIndex={props.notTabbable ? '-1' : '0'}
    onKeyDown={handleKeyPress}
    role="checkbox"
    aria-checked={props.checked}
  >
    {props.checked ? <FontAwesomeIcon className="checkbox__tick" icon={faCheck} /> : null}
  </div>
}

const StyledCheckbox = styled(Checkbox)`
  width: var(--checkboxSize);
  height: var(--checkboxSize);
  background: var(--whiteText);
  position: relative;
  color: var(--success);

  .checkbox__tick.checkbox__tick {
    position: absolute;
    width: 90%;
    height: 90%;
    left: 2px;
    bottom: 2px;
  }
`

export default StyledCheckbox
