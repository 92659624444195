import React from 'react'
import styled from 'styled-components'

import map from '../images/map.png'


const Map = props => {
  return <div className={props.className + (props.locationIsStart ? ' locationIsStart' : '')}>
    {props.children}
  </div>
}

const StyledMap = styled(Map)`
  position: relative;
  width: 75vmin;
  height: 60vmin;
  border: 3px solid var(--primaryBg);
  box-sizing: content-box;
  border-radius: 8px;
  background: url(${map}) no-repeat center;
  background-size: contain;

  &:before {
    content: "";
    position: absolute;
    width: ${props => props.largeLocation ? '14vmin' : props.locationIsStart ? '7vmin' : '4vmin'};
    height: ${props => props.largeLocation ? '14vmin' : props.locationIsStart ? '7vmin' : '4vmin'};
    border-radius: 50%;
    border: 0.6vmin solid var(--locationMarker);
    box-shadow:
      0 0 0.6vmin 0.5vmin var(--locationMarkerGlow),
      0 0 0.6vmin 0.5vmin var(--locationMarkerGlow) inset;
    left: ${props => props.x / 100}%;
    top: ${props => props.y / 100}%;
    transform: translate(-50%, -50%);
    opacity: 0.95;
  }

  ${props => {
    if (!props.largeLocation && !props.locationIsStart) {
      return `
        &:after {
          content: "";
          position: absolute;
          width: 3vmin;
          height: 3vmin;
          border-radius: 50%;
          background: var(--locationMarker);
          box-shadow:
            0 0 0.6vmin 0.5vmin var(--locationMarkerGlow);
          left: ${props.x / 100}%;
          top: ${props.y / 100}%;
          transform: translate(-50%, -50%);
          opacity: 0.95;
        }
      `
    }
  }}

  &.locationIsStart:after {
    content: "Start here";
    position: absolute;
    left: ${props => props.x / 100}%;
    top: ${props => props.y / 100}%;
    width: min-content;
    transform: translate(-50%, -50%);
    color: var(--locationMarker);
    text-transform: uppercase;
    font-style: normal;
    font-size: 12rem;
    text-shadow:
      0 0 8px var(--locationMarkerGlow),
      -1px -1px 0 var(--locationMarkerGlow),
      -1px 1px 0 var(--locationMarkerGlow),
      1px -1px 0 var(--locationMarkerGlow),
      1px 1px 0 var(--locationMarkerGlow);
  }
`

export default StyledMap
