import React from 'react'

/* TODO
  - update gatsby
  * add home screen image
  * add favicon
  * add page title
  - check use of "Link... Link... Link"
  - check each modal at all screen sizes
  - check all warnings/errors in gatsby develop
  - look at reducing font sizes on smaller screens
  * spell check
  * proof read
  - check for consistency with:
    - capitalisation
    - blue and red text
*/

export const objectives = [
  {
    order: 1,
    handle: 'tower',
    title: "Activate the Great Plateau Tower",
    additional: <p>Use the in-game advice to find the tower.</p>,
    success: <p><em>Oman Au Shrine</em> is the only one that has become active and is therefore the only shrine you can enter for now.</p>,
    x: 4399,
    y: 6918,
  },
  
  {
    order: 2,
    handle: 'omanAu',
    title: "Complete Oman Au Shrine",
    additional: <p>You may use Magnesis to complete this shrine but <strong>cannot use it again until you have unlocked it</strong>.</p>,
    success: <>
      <p>All other shrines have been activated.</p>
      <div className="dialogueBox">
        <div className="character">Old Man <small>(in addition to what he says in-game)</small></div>
        <div className="dialogue">
          <p>In order to reach the other three shrines you'll need some <strong>climbing gear</strong>. I can give you mine, but I need you to do something for me first. Monsters that have made the <em>Forest of Spirits</em> their home have stolen some items from me. If you can recover what they took and return them to my hut in the southeast corner of the Great Plateau, the climbing gear is yours. The items you're looking for are <strong>two pieces of opal and a bundle of fire arrows</strong>. You'll find them in guarded chests in and around the Forest of Spirits.</p>
        </div>
      </div>
    </>,
    x: 4288,
    y: 6716,
  },

  {
    order: 3,
    handle: 'forestOfSpirits',
    title: "Open three chests guarded by enemies in and around the Forest of Spirits",
    additional: <>
      <p>The three chests contain:</p>
      <ul className="bullets">
        <li>Opal</li>
        <li>Opal</li>
        <li>Fire arrows</li>
      </ul>
    </>,
    success: <p>Take the two pieces of opal and the bundle of fire arrows to the <em>Old Man's hut</em>.</p>,
    x: 3888,
    y: 6853,
  },

  {
    order: 4,
    handle: 'hut',
    title: "Speak to the Old Man outside his hut",
    additional: <p>The hut is located in the <em>southeast corner of the Great Plateau</em></p>,
    success: <>
      <div className="dialogueBox">
        <div className="character">Old Man <small>(in place of his usual speech)</small></div>
        <div className="dialogue">
          <p>Wonderful! You were able to recover my stolen items! As promised, here is my <strong>climbing gear</strong>. Good luck seeking out the remaining <strong>spirit orbs</strong>.</p>
        </div>
      </div>
      <div className="newUnlock">You can now climb walls and other surfaces!</div>
    </>,
    x: 4200,
    y: 7600,
  },

  {
    order: 5,
    handle: 'remainingShrines',
    title: "Collect the Great Plateau's three remaining spirit orbs",
    additional: <>
      <p>Complete the Great Plateau's remaining shrines to obtain <strong>spirit orbs</strong>.</p>
      <p>You may use each shrine's respective rune to complete it but <strong>cannot use the runes again until you have unlocked them</strong>.</p>
    </>,
    success: <p>Follow the Old Man's instructions to find him.</p>,
    x: 3975,
    y: 7150,
    largeLocation: true,
  },

  {
    order: 6,
    handle: 'templeOfTime',
    title: "Meet the Old Man",
    additional: <p>Use the Old Man's in-game instructions to find him.</p>,
    success: <p>Head to Kakariko Village to meet Impa as instructed by King Rhoam.</p>,
    x: 3975,
    y: 7150,
    largeLocation: true,
  },

  {
    order: 7,
    handle: 'impa1',
    title: "Find Impa in Kakariko Village",
    additional: <p>Use in-game information to locate Kakariko Village.</p>,
    success: <>
      <div className="dialogueBox">
        <div className="character">Impa <small>(in place of what she says about Hateno Village in-game)</small></div>
        <div className="dialogue">
          <p>Unfortunately, you are not yet equipped to take on the Divine Beasts. You cannot hope to defeat the evil dwelling inside without <strong>the sword that seals the darkness</strong>.</p>
          <p>This legendary blade, known as the <strong>Master Sword</strong>, was wielded by you 100 years ago. After you fell, it was taken to <em>Great Hyrule Forest</em> to be restored, and a barrier was placed around the forest to keep the sword hidden. The only one who can remove this barrier is <strong>Monk Mirro Shaz</strong>, but to enter his shrine you will need to obtain three pearls.</p>
          <p>The first pearl is in the <em>Akkala</em> region, in the northeast corner of Hyrule. To find it, you should travel north from here beyond <em>Lanayru Wetlands</em>. Keep to the right of <em>Death Mountain</em>, then bear left at <em>Akkala Citadel</em>. The shrine you seek belongs to Monk Ze Kasho and rests on a hillside overlooking <em>South Akkala Stable</em>.</p>
          <p>Return here once you have the Pearl of Akkala and I shall guide you further.</p>
        </div>
      </div>
    </>,
    x: 4154,
    y: 7266,
    locationIsStart: true,
  },

  {
    order: 8,
    handle: 'zeKasho',
    title: "Enter Ze Kasho Shrine",
    additional: <>
      <div className="dialogueBox">
        <div className="character">Impa<small>'s instructions</small></div>
        <div className="dialogue">
          <p>The first pearl is in the <em>Akkala</em> region, in the northeast corner of Hyrule. To find it, you should travel north from here beyond <em>Lanayru Wetlands</em>. Keep to the right of <em>Death Mountain</em>, then bear left at <em>Akkala Citadel</em>. The shrine you seek belongs to Monk Ze Kasho and rests on a hillside overlooking <em>South Akkala Stable</em>.</p>
        </div>
      </div>
      <div className="note">This objective is complete as soon as you enter the shrine.</div>
    </>,
    success: <div className="newUnlock">You can now use Remote Bombs!</div>,
    x: 6721,
    y: 6066,
    locationIsStart: true,
  },

  {
    order: 9,
    handle: 'zeKashoA',
    title: "Complete Ze Kasho Shrine",
    success: <>
      <div className="dialogueBox">
        <div className="character">Monk Ze Kasho</div>
        <div className="dialogue">
          <p>Overcoming this shrine was no small feat. In recognition of your achievement I shall grant you this <strong>boss key</strong>. Use it to enter <em>Dah Hesho Shrine</em> and earn the Pearl of Akkala in a test of strength.</p>
          <p>You will find Dah Hesho Shrine on the far side of <em>Akkala Citadel</em>, on a <strong>clifftop overlooking the lake below</strong>.</p>
        </div>
      </div>
    </>,
    x: 7924,
    y: 2822,
  },

  {
    order: 10,
    handle: 'dahHesho',
    title: "Complete Dah Hesho Shrine",
    additional: <>
      <div className="dialogueBox">
        <div className="character">Monk Ze Kasho<small>'s instructions</small></div>
        <div className="dialogue">
          <p>You will find Dah Hesho Shrine on the far side of <em>Akkala Citadel</em>, on a <strong>clifftop overlooking the lake below</strong>.</p>
        </div>
      </div>
    </>,
    success: <>
      <p>As well as the usual spirit orb, Monk Dah Hesho also hands you the <strong>Pearl of Akkala</strong>.</p>
      <p>Return to Impa for instructions on how to find the next pearl.</p>
    </>,
    x: 7924,
    y: 2822,
    locationIsStart: true,
  },

  {
    order: 11,
    handle: 'impa2',
    title: "Return to Impa with the Pearl of Akkala",
    success: <>
      <div className="dialogueBox">
        <div className="character">Impa <small>(in place of her usual speech)</small></div>
        <div className="dialogue">
          <p>Ah, I see you carry the first pearl. I am impressed you were able to obtain it so quickly without your memories to guide you. The next pearl rests in <em>Faron</em> on Hyrule's southern coast, but before you can start your quest you must head to <em>Hateno Village</em> and seek out a Sheikah woman named <strong>Purah</strong>. Purah can unlock new abilities on your Sheikah Slate that are essential to gain access to <em>Kah Yah Shrine</em>.</p>
          <p>Once your Sheikah Slate is ready, head south from Purah's lab towards <em>Hateno Beach</em>, then cross the bays and circle around the cliffs in the distance. You will arrive at <em>Palmorae Ruins</em> where a <strong>monument lies fragmented</strong>, this is where your quest begins.</p>
        </div>
      </div>
    </>,
    x: 6721,
    y: 6066,
  },

  {
    order: 12,
    handle: 'purah',
    title: "Find Purah and upgrade your Sheikah Slate",
    additional: <p>Purah lives in <em>Hateno Village</em>, southeast of <em>Kakariko Village</em>.</p>,
    success: <>
      <p>Travel south as instructed by Impa to begin your quest for the <strong>Pearl of Faron</strong>.</p>
      <p>While you were in Hateno Village, you heard a rumour about three <strong>large stone labyrinths</strong> hidden across Hyrule. The rumour suggests that each one holds a unique weapon that could aid you on your quest. You should keep this in mind and look out for these labyrinths as you travel.</p>
    </>,
    x: 8651,
    y: 7458,
  },

  {
    order: 13,
    handle: 'kahYah',
    title: "Enter Kah Yah Shrine",
    additional: <>
      <div className="dialogueBox">
        <div className="character">Impa<small>'s instructions</small></div>
        <div className="dialogue">
          <p>Head south from Purah's lab towards <em>Hateno Beach</em>, then cross the bays and circle around the cliffs in the distance. You will arrive at <em>Palmorae Ruins</em> where a <strong>monument lies fragmented</strong>, this is where your quest begins.</p>
        </div>
      </div>
      <div className="note">This objective is complete as soon as you enter the shrine.</div>
    </>,
    success: <div className="newUnlock">You can now use bows and arrows!</div>,
    x: 8651,
    y: 7458,
    locationIsStart: true,
  },

  {
    order: 14,
    handle: 'kahYahA',
    title: "Complete Kah Yah Shrine",
    success: <>
      <div className="dialogueBox">
        <div className="character">Monk Kah Yah</div>
        <div className="dialogue">
          <p>You have done well to overcome this shrine. Take this <strong>boss key</strong> and use it to enter <em>Muwo Jeem Shrine</em>, which sits <strong>atop the cliffs behind this shrine</strong>.</p>
        </div>
      </div>
    </>,
    x: 8320,
    y: 8924,
  },

  {
    order: 15,
    handle: 'muwoJeem',
    title: "Complete Muwo Jeem Shrine",
    additional: <>
      <div className="dialogueBox">
        <div className="character">Monk Kah Yah<small>'s instructions</small></div>
        <div className="dialogue">
          <p>Take this <strong>boss key</strong> and use it to enter <em>Muwo Jeem Shrine</em>, which sits <strong>atop the cliffs behind this shrine</strong>.</p>
        </div>
      </div>
    </>,
    success: <>
      <p>As well as the usual spirit orb, Monk Muwo Jeem also hands you the <strong>Pearl of Faron</strong>.</p>
      <p>Return to Impa for instructions on how to find the final pearl.</p>
    </>,
    x: 8320,
    y: 8924,
    locationIsStart: true,
  },

  {
    order: 16,
    handle: 'impa3',
    title: "Return to Impa with the Pearl of Faron",
    success: <>
      <div className="dialogueBox">
        <div className="character">Impa <small>(in addition to what she says in-game)</small></div>
        <div className="dialogue">
          <p>You have done well to obtain two pearls already. Only the <strong>Pearl of Tabantha</strong> remains. For this pearl, you must travel west beyond Hyrule Field and Hyrule Ridge to <em>Tabantha Great Bridge</em>. Glide off the bridge's southern side and follow <em>Tanagar Canyon</em> to its end, then to turn to your right and seek out the concealed <em>Kah Okeo Shrine</em>.</p>
          <p>Your quest is taking you farther and farther across Hyrule; have you happened upon the elemental dragons who soar the skies of this land? You can harness these dragons' powers by collecting their scales with a well-aimed arrow and offering them at their respective springs. The springs are located deep within <em>Faron's forests</em>, atop <em>Mount Lanayru</em>, and in the <em>valleys of Akkala</em>.</p>
        </div>
      </div>
    </>,
    x: 6721,
    y: 6066,
  },

  {
    order: 17,
    handle: 'kahOkeo',
    title: "Enter Kah Okeo Shrine",
    additional: <>
      <div className="dialogueBox">
        <div className="character">Impa<small>'s instructions</small></div>
        <div className="dialogue">
          <p>Only the <strong>Pearl of Tabantha</strong> remains. For this pearl, you must travel west beyond Hyrule Field and Hyrule Ridge to <em>Tabantha Great Bridge</em>. Glide off the bridge's southern side and follow <em>Tanagar Canyon</em> to its end, then to turn to your right and seek out the concealed <em>Kah Okeo Shrine</em>.</p>
        </div>
      </div>
      <div className="note">This objective is complete as soon as you enter the shrine.</div>
    </>,
    success: <div className="newUnlock">You can now use Korok Leaves!</div>,
    x: 6721,
    y: 6066,
    locationIsStart: true,
  },

  {
    order: 18,
    handle: 'kahOkeoA',
    title: "Complete Kah Okeo Shrine",
    success: <>
      <div className="dialogueBox">
        <div className="character">Monk Kah Okeo</div>
        <div className="dialogue">
          <p>In overcoming this shrine you have shown that you possess skills worthy of the title "hero". You must now prove yourself in battle with <strong>Monk Tena Ko'sah</strong>. Use this <strong>boss key</strong> to enter the shrine <strong>amidst the ruins at the top of the hill</strong> east of here.</p>
        </div>
      </div>
    </>,
    x: 911,
    y: 4362,
  },

  {
    order: 19,
    handle: 'tenaKosah',
    title: "Complete Tena Ko'sah Shrine",
    additional: <>
      <div className="dialogueBox">
        <div className="character">Monk Kah Okeo<small>'s instructions</small></div>
        <div className="dialogue">
          <p>You must now prove yourself in battle with <strong>Monk Tena Ko'sah</strong>. Use this <strong>boss key</strong> to enter the shrine <strong>amidst the ruins at the top of the hill</strong> east of here.</p>
        </div>
      </div>
    </>,
    success: <>
      <p>As well as the usual spirit orb, Monk Tena Ko'sah also hands you the <strong>Pearl of Tabantha</strong>.</p>
      <p>Return to Impa for instructions on where to travel next.</p>
    </>,
    x: 911,
    y: 4362,
    locationIsStart: true,
  },

  {
    order: 20,
    handle: 'impa4',
    title: "Return to Impa with the Pearl of Tabantha",
    success: <>
      <div className="dialogueBox">
        <div className="character">Impa <small>(in place of her usual speech)</small></div>
        <div className="dialogue">
          <p>Well done, Link. With all three pearls you are now ready to continue your quest for the Master Sword.</p>
          <p>In order to dispel the barrier surrounding <em>Great Hyrule Forest</em>, you must use the three pearls to enter <em>Mirro Shaz Shrine</em> and speak with Monk Mirro Shaz. You will find this shrine near the entrance to the forest in the <strong>foothills of Death Mountain behind Woodland Stable</strong>.</p>
        </div>
      </div>
    </>,
    x: 6721,
    y: 6066,
  },

  {
    order: 21,
    handle: 'mirroShaz',
    title: "Enter Mirro Shaz Shrine",
    additional: <>
      <div className="dialogueBox">
        <div className="character">Impa<small>'s instructions</small></div>
        <div className="dialogue">
          <p>You will find this shrine near the entrance to the forest in the <strong>foothills of Death Mountain behind Woodland Stable</strong>.</p>
        </div>
      </div>
      <div className="note">This objective is complete as soon as you enter the shrine.</div>
    </>,
    success: <div className="newUnlock">You can now use Stasis!</div>,
    x: 6500,
    y: 2600,
    largeLocation: true,
  },

  {
    order: 22,
    handle: 'mirroShazA',
    title: "Complete Mirro Shaz Shrine",
    success: <>
      <div className="dialogueBox">
        <div className="character">Mirro Shaz</div>
        <div className="dialogue">
          <p>Your quest for the sword that seals the darkness is almost over. <strong>Follow the wind</strong> into Great Hyrule Forest and reclaim your blade.</p>
        </div>
      </div>
    </>,
    x: 6160,
    y: 3375,
  },

  {
    order: 23,
    handle: 'masterSword',
    title: "Obtain the Master Sword",
    additional: <div className="note">The game requires you to have 13 hearts before you can collect the Master Sword. In this playthrough this is difficult to achieve at this point, so you may choose to visit the forest and speak with the Great Deku Tree, then continue through the quest as if you had collected the Master Sword. You can then return later once you have reached 13 hearts.</div>,
    success: <>
      <div className="dialogueBox">
        <div className="character">Zelda</div>
        <div className="dialogue">
          <p>Link... Link... Link.</p>
          <p>I can sense the Master Sword's power in your hands. With this weapon in your arsenal, you are now ready to regain control of the Divine Beasts.</p>
          <p>The first of these is <strong>Vah Rudania</strong>. Climb <em>Death Mountain</em> and speak with the <strong>Gorons</strong> to devise a plan for reaching the Divine Beast.</p>
          <p>Please, hurry Link.</p>
        </div>
      </div>
      <p>While in the forest, one of the Koroks commented that you look weary from your travels. They told you of some mysterious ruins that lie to the north of the forest and that a secret hidden within them can help you with this.</p>
    </>,
    x: 5270,
    y: 2160,
    largeLocation: true,
  },

  {
    order: 24,
    handle: 'yunobo',
    title: "Unlock the memory \"Daruk's Mettle\" (recovered memory #4)",
    additional: <p>Head to <em>Goron City</em> on Death Mountain and follow the in-game quest <strong>"Divine Beast Vah Rudania"</strong> to unlock the memory.</p>,
    success: <p>Your memory of Daruk included knowledge of <strong>an ability that you'll need before entering Vah Rudania</strong>. This ability lies within <em>Shora Hah Shrine</em>, which can be reached by riding a minecart across <em>Lake Darman</em> to the <em>Isle of Rabac</em>.</p>,
    x: 7050,
    y: 1800,
    largeLocation: true,
  },

  {
    order: 25,
    handle: 'shoraHah',
    title: "Enter Shora Hah Shrine",
    additional: <>
      <p>You remembered that an ability found inside <em>Shora Hah Shrine</em> is needed before entering Vah Rudania, and that this shrine can be reached by riding a minecart across <em>Lake Darman</em> to the <em>Isle of Rabac</em>.</p>
      <div className="note">This objective is complete as soon as you enter the shrine.</div>
    </>,
    success: <div className="newUnlock">You can now use Magnesis!</div>,
    x: 6601,
    y: 1842,
    locationIsStart: true,
  },

  {
    order: 26,
    handle: 'shoraHahA',
    title: "Complete Shora Hah Shrine",
    success: <p>Continue with the in-game quest to enter <strong>Divine Beast Vah Rudania</strong>.</p>,
    x: 6456,
    y: 1039,
  },

  {
    order: 27,
    handle: 'vahRudania',
    title: "Complete Divine Beast Vah Rudania",
    additional: <p>Follow the in-game quest to complete this objective.</p>,
    success: <>
      <div className="dialogueBox">
        <div className="character">Zelda</div>
        <div className="dialogue">
          <p>Link... Link... Link.</p>
          <p>With Vah Rudania back under our command we have begun to turn the tides on Calamity Ganon, but there are still three Divine Beasts that remain influenced by his malace.</p>
          <p>The next of these is <strong>Vah Medoh</strong>. Travel east and head towards the <strong>giant bird that soars through the skies</strong>. The <strong>Rito</strong> tribe who live nearby can help you gain entry to Vah Medoh.</p>
          <p>Good luck, Link!</p>
        </div>
      </div>
    </>,
    x: 7300,
    y: 1700,
    largeLocation: true,
  },

  {
    order: 28,
    handle: 'teba',
    title: "Unlock the memory \"Revali's Flap\" (recovered memory #2)",
    additional: <p>Head to <em>Rito Village</em> beneath Hebra Mountains and follow the in-game quest <strong>"Divine Beast Vah Medoh"</strong> to unlock the memory.</p>,
    success: <p>Your memory of Revali included knowledge of <strong>an ability that you'll need before entering Vah Medoh</strong>. This ability lies within <em>Rok Uwog Shrine</em>, which can be found in the shadows of <em>Pikida Stonegrove</em> to the east of Hebra's tallest peak.</p>,
    x: 1500,
    y: 2200,
    largeLocation: true,
  },

  {
    order: 29,
    handle: 'rokUwog',
    title: "Enter Rok Uwog Shrine",
    additional: <>
      <p>You remembered that an ability found inside <em>Rok Uwog Shrine</em> is needed before entering Vah Medoh, and that this shrine can be found in the shadows of <em>Pikida Stonegrove</em> to the east of Hebra's tallest peak.</p>
      <div className="note">This objective is complete as soon as you enter the shrine.</div>
    </>,
    success: <div className="newUnlock">You can now use Bomb Arrows!</div>,
    x: 1414,
    y: 2661,
    locationIsStart: true,
  },

  {
    order: 30,
    handle: 'rokUwogA',
    title: "Complete Rok Uwog Shrine",
    success: <p>Continue with the in-game quest to enter <strong>Divine Beast Vah Medoh</strong>.</p>,
    x: 2621,
    y: 915,
  },

  {
    order: 31,
    handle: 'vahMedoh',
    title: "Complete Divine Beast Vah Medoh",
    additional: <p>Follow the in-game quest to complete this objective.</p>,
    success: <>
      <div className="dialogueBox">
        <div className="character">Zelda</div>
        <div className="dialogue">
          <p>Link... Link... Link.</p>
          <p>Half of the Divine Beasts are now back under our control!</p>
          <p>Head south to <em>Gerudo Desert</em> where the <strong>Gerudo</strong> can assist you in entering the third Divine Beast, <strong>Vah Naboris</strong>.</p>
          <p>I know you can do this Link!</p>
        </div>
      </div>
    </>,
    x: 1425,
    y: 2625,
    largeLocation: true,
  },

  {
    order: 32,
    handle: 'riju',
    title: "Unlock the memory \"Urbosa's Hand\" (recovered memory #6)",
    additional: <p>Head to <em>Gerudo Town</em> in the middle of the desert and follow the in-game quest <strong>"Divine Beast Vah Naboris"</strong> to unlock the memory.</p>,
    success: <p>Your memory of Urbosa included knowledge of <strong>an ability that you'll need before entering Vah Rudania</strong>. This ability lies within <em>Hawa Koth Shrine</em>, which can be found beneath the <em>Gerudo Great Skeleton</em> in the farthest reaches of the desert.</p>,
    x: 1200,
    y: 8650,
    largeLocation: true,
  },

  {
    order: 33,
    handle: 'hawaKoth',
    title: "Enter Hawa Koth Shrine",
    additional: <>
      <p>You remembered that an ability found inside <em>Hawa Koth Shrine</em> is needed before entering Vah Naboris, and that this shrine can be found beneath the <em>Gerudo Great Skeleton</em> in the farthest reaches of the desert.</p>
      <div className="note">This objective is complete as soon as you enter the shrine.</div>
    </>,
    success: <div className="newUnlock">You can now shield surf!</div>,
    x: 1170,
    y: 8465,
    locationIsStart: true,
  },

  {
    order: 34,
    handle: 'hawaKothA',
    title: "Complete Hawa Koth Shrine",
    success: <p>Continue with the in-game quest to enter <strong>Divine Beast Vah Naboris</strong>.</p>,
    x: 200,
    y: 9495,
  },

  {
    order: 35,
    handle: 'vahNaboris',
    title: "Complete Divine Beast Vah Naboris",
    additional: <p>Follow the in-game quest to complete this objective.</p>,
    success: <>
      <div className="dialogueBox">
        <div className="character">Zelda</div>
        <div className="dialogue">
          <p>Link... Link... Link.</p>
          <p>Only one Divine Beast - <strong>Vah Ruta</strong> - remains under Calamity Ganon's influence!</p>
          <p>Seek out <em>Zora's Domain</em> south of Akkala and east of Lanayru Wetlands. The Zora there can help you to gain access to the final Divine Beast.</p>
          <p>Victory is within our grasp!</p>
        </div>
      </div>
    </>,
    x: 2000,
    y: 8650,
    largeLocation: true,
  },

  {
    order: 36,
    handle: 'dorephan',
    title: "Unlock the memory \"Mipha's Touch\" (recovered memory #10)",
    additional: <>
      <p>Head to <em>Zora's Domain</em> in northern Lanayru and follow the in-game quest <strong>"Divine Beast Vah Ruta"</strong> to unlock the memory.</p>
      <div className="note">All mentions of challenging the Lynel at <em>Shatterback Point</em> in the upcoming Divine Beast quest state that you need to <strong>slay the Lynel</strong> rather than avoid it. You must defeat it before challenging Vah Ruta with Prince Sidon.</div>
    </>,
    success: <p>Your memory of Mipha included knowledge of <strong>an ability that you'll need before entering Vah Ruta</strong>. This ability lies within <em>Ne'ez Yohma Shrine</em>, the shrine located within <em>Zora's Domain</em>.</p>,
    x: 7800,
    y: 4600,
    largeLocation: true,
  },

  {
    order: 37,
    handle: 'neezYohma',
    title: "Enter Ne'ez Yohma Shrine",
    additional: <>
      <p>You remembered that an ability found inside <em>Ne'ez Yohma Shrine</em> - the shrine located within <em>Zora's Domain</em> - is needed before entering Vah Ruta.</p>
      <div className="note">This objective is complete as soon as you enter the shrine.</div>
    </>,
    success: <div className="newUnlock">You can now use Cryonis!</div>,
    x: 8200,
    y: 4100,
  },

  {
    order: 38,
    handle: 'neezYohmaA',
    title: "Complete Ne'ez Yohma Shrine",
    success: <p>Continue with the in-game quest to enter <strong>Divine Beast Vah Ruta</strong>.</p>,
    x: 8200,
    y: 4100,
  },

  {
    order: 39,
    handle: 'lynel',
    title: "Defeat the Lynel at Shatterback Point",
    additional: <div className="note">For the purposes of this quest, the shock arrows collected by defeating the Lynel are considered separate from regular shock arrows. You may therefore use shock arrows during the attack on Vah Ruta even if you haven't unlocked electric attacks.</div>,
    success: <p>Continue with the in-game quest to enter <strong>Divine Beast Vah Rudania</strong>.</p>,
    x: 8730,
    y: 4163,
  },

  {
    order: 40,
    handle: 'vahRuta',
    title: "Complete Divine Beast Vah Ruta",
    additional: <>
      <p>Follow the in-game quest to complete this objective.</p>
      <div className="note">For the purposes of this quest, the shock arrows collected by defeating the Lynel are considered separate from regular shock arrows. You may therefore use shock arrows during the attack on Vah Ruta even if you haven't unlocked electric attacks.</div>
    </>,
    success: <p>Follow Zelda's in-game advice and head for <em>Hyrule Castle</em>.</p>,
    x: 8545,
    y: 4654,
  },
  
  {
    order: 41,
    handle: 'ganon',
    title: "Defeat Calamity Ganon",
    additional: <p>Follow the in-game quest to complete this objective.</p>,
    success: <>
      <p className="contentHeading">Congratulations!</p>
      <p>Thank you for playing this quest!</p>
    </>,
    x: 4725,
    y: 3551,
  },
]

export const sideQuests = [
  {
    order: 0,
    handle: 'dilaMaag', // Gerudo labyrinth
    title: "Dila Maag Shrine",
    success: <div className="newUnlock">You can now use boomerang weapons!</div>,
  },
  {
    order: 0,
    handle: 'jitanSami', // Spring of Wisdom
    title: "Jitan Sa'mi Shrine",
    success: <div className="newUnlock">You can now use Ice Arrows and frost-powered weapons!</div>,
  },
  {
    order: 0,
    handle: 'ketohWawai', // Thyphlo Ruins
    title: "Ketoh Wawai Shrine",
    success: <div className="newUnlock">You can now collect Stamina Vessels!</div>,
  },
  {
    order: 0,
    handle: 'qazaTokki', // Tabantha labyrinth
    title: "Qaza Tokki Shrine",
    success: <div className="newUnlock">You can now use hammer weapons!</div>,
  },
  {
    order: 0,
    handle: 'shaeKatha', // Spring of Courage
    title: "Shae Katha Shrine",
    success: <div className="newUnlock">You can now use Shock Arrows and thunder-powered weapons!</div>,
  },
  {
    order: 0,
    handle: 'tuKaloh', // Akkala labyrinth
    title: "Tu Ka'loh Shrine",
    success: <div className="newUnlock">You can now use axe weapons!</div>,
  },
  {
    order: 0,
    handle: 'tutsuwaNima', // Spring of Power
    title: "Tutsuwa Nima Shrine",
    success: <div className="newUnlock">You can now use Fire Arrows and flame-powered weapons! You can also now use Flint and Wood to start a fire.</div>,
  },
]
