import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'

const RestritedShrines = props => {
  const shrines = [
    {name: "Dah Hesho", requires: 'zeKasho'},
    {name: "Hawa Koth", requires: 'riju'},
    {name: "Ja Baij", requires: 'omanAu'},
    {name: "Kah Okeo", requires: 'impa3'},
    {name: "Kah Yah", requires: 'purah'},
    {name: "Keh Namut", requires: 'omanAu'},
    {name: "Mirro Shaz", requires: 'impa4'},
    {name: "Muwo Jeem", requires: 'kahYah'},
    {name: "Ne'ez Yohma", requires: 'dorephan'},
    {name: "Oman Au", requires: 'tower'},
    {name: "Owa Dam", requires: 'omanAu'},
    {name: "Rok Uwog", requires: 'teba'},
    {name: "Shora Hah", requires: 'yunobo'},
    {name: "Tena Ko'sah", requires: 'kahOkeo'},
    {name: "Ze Kasho", requires: 'impa1'},
  ]

  const lis = shrines.map((shrine, index) => (
    <li className={props.completed[shrine.requires] ? 'available' : 'locked'} key={index}>
      {props.completed[shrine.requires] ? null : <FontAwesomeIcon className="restrictedShrines__locked mr-1" icon={faBan} />}
      {shrine.name} Shrine
      {props.completed[shrine.requires] ? null : <FontAwesomeIcon className="restrictedShrines__locked ml-1" icon={faBan} />}
    </li>
  ))

  return <div className={props.className}>
    <p>The shrines below (listed in alphabetical order) cannot be accessed until a certain point in the quest. Once a shrine becomes available to you it will be crossed out and moved to the lower part of the list.</p>
    <p>When you stumble across a shrine, you can check its name against this list to confirm that it's not one of the ones you can't access yet.</p>
    <ul className={'pt-2' + (!props.completed.tower || props.completed.dorephan ? '' : ' split')}>
      {lis}
    </ul>
  </div>
}

const StyledRestritedShrines = styled(RestritedShrines)`
  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    &.split:after {
      content: "";
      display: block;
      height: 16px;
      order: 2;
    }
  }
  li {
    position: relative;
    margin-top: 4px;
    &.locked {
      order: 1;
    }
    &.available {
      order: 3;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: -4px;
        right: -4px;
        height: 3px;
        background: var(--success);
      }
    }
  }

  .restrictedShrines__locked {
    color: var(--redText);
  }
`

export default StyledRestritedShrines
