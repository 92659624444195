import React from 'react'
import styled from 'styled-components'

import Accordion from './Accordion'


const Tips = props => {
  return <div className={props.className}>
    <Accordion title="Tips" expandedByDefault={props.expandedByDefault}>
      <ul className="spaced">
        <li>
          <strong>New save file</strong><br/>
          To start a new save file without erasing your old one, create a new user account on your Switch/Wii U.
        </li>
        <li>
          <strong>Quick access</strong><br/>
          You can add this website to your phone's home screen to quickly access it like an app (<a href="https://natomasunified.org/kb/add-website-to-mobile-device-home-screen/" target="_blank" rel="noreferrer">instructions for iOS and Android</a>).
        </li>
        <li>
          <strong>Mistakes</strong><br/>
          If you accidentally break one of the restrictions you can use the game's autosave feature to go back to a point before you did so.
        </li>
        <li>
          <strong>Reset progress</strong><br/>
          To reset your progress on this website, simply untick the first objective and all completed side quests.
        </li>
        <li>
          <strong>Master Mode</strong><br/>
          This quest has not been tested in Master Mode so there is no guarantee it will work smoothly.
        </li>
      </ul>
    </Accordion>
  </div>
}

const StyledTips = styled(Tips)`
  strong {
    color: inherit;
    font-variant-caps: small-caps;
    font-size: 120%;
  }
`

export default StyledTips
