import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'

import lineageGaming from '../images/lineage-gaming.png'


const Footer = props => {
  return <footer className={props.className}>
    <a href="http://lineagegaming.co.uk" target="_blank" className="pageFooter__lineage" rel="noreferrer">
        <div>
            <div className="pageFooter__lineageText">
                <div className="pageFooter__lineageTextInner">The Breath of the Wild Traditional Quest is brought to you&nbsp;by</div>
            </div>
            <img src={lineageGaming} alt="Lineage Gaming" />
        </div>
    </a>
    <div className="pageFooter__body">
        <div className="pageFooter__social">
            <a href="https://twitter.com/intent/follow?ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Efollow%7Ctwgr%5ELineage_Games&region=follow_link&screen_name=Lineage_Games" target="_blank">
                <div className="icon">
                    <FontAwesomeIcon icon={faTwitter} />
                </div>
                <span>Follow Lineage Gaming on Twitter</span>
            </a>
        </div>
    </div>
</footer>
}

const StyledFooter = styled(Footer)`
    position: relative;
    padding: 40px 0;
    margin-top: 80px;
    background: #222222;
    color: #bbbbbb;
    box-shadow:
        0 0 40px 20px rgba(128, 128, 96, 0.12),
        ${'' /* The box-shadows below add a background that matches the footer behind the sticky inventory. If this background has place in the flow (e.g. a
        position:absolute :after) it adds space below the sticky inventory, but if overflow:hidden is used to wrap the page the inventory loses its stickiness. */}
        0 100px 0 0 #222222,
        0 200px 0 0 #222222,
        0 300px 0 0 #222222,
        0 400px 0 0 #222222;

    a {
        text-decoration: none;
    }

    .pageFooter__lineage {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 50%;
        text-align: center;
        background: #c3b08a;
        color: white;
        font-size: 16rem;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        z-index: 1;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #000000;
            opacity: 0;
            pointer-events: none;
            z-index: 2;
            transition: 0.3s all ease;
        }
        &:hover, &:focus, &:active {
            color: white;
        }
        &:hover, &:focus {
            &:before {
                opacity: 0.05;
            }
        }
        > div {
            height: 100%;
            padding-top: 24px;
            @media screen and (max-width: 920px) {
                padding-top: 40px;
            }
            @media screen and (max-width: 540px) {
                padding-top: 48px;
            }
        }
        &Text {
            height: 0;
            position: relative;
            z-index: 1;
            line-height: 1;
            &Inner {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                transform: translateY(-50%);
                padding: 0 12px;
                @media screen and (max-width: 920px) {
                    transform: translateY(-80%);
                }
                @media screen and (max-width: 400px) {
                    font-size: 80%;
                }
            }
        }
        img {
            height: 100%;
            object-fit: contain;
            margin: 0 auto;
        }
    }

    .pageFooter__body {
        --paddingX: 8px;
        @media screen and (min-width: 420px) {
            --paddingX: 12px;
        }
        @media screen and (min-width: 768px) {
            --paddingX: 16px;
        }
        @media screen and (min-width: 1200px) {
            --paddingX: 32px;
        }
        padding-left: calc(50vw + var(--paddingX));
        padding-right: var(--paddingX);
        min-height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        a {
            color: #dddddd;
            &:hover, &:focus {color: white;}
        }
    }
    
    .pageFooter__legal {
        font-size: 16rem;
        @media screen and (max-width: 575px) {
            font-size: 12rem;
        }
    }

    .pageFooter__social {
        margin-bottom: 24px;
        font-size: 18rem;
        line-height: 1.1;
        a {
            display: flex;
            align-items: center;

            .icon {
                font-size: 30rem;
                margin-right: 16px;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: calc(50% - 4px);
                    width: 8px;
                    height: 8px;
                    transform: translate(-50%, -50%);
                    opacity: 0.2;
                    background: currentColor;
                    border-radius: 50%;
                    box-shadow: 0 0 30px 24px currentColor;
                }
            }
        }
        @media screen and (max-width: 575px) {
            font-size: 14rem;
        }
    }
`

export default StyledFooter
