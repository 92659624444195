import React from 'react'
import styled from 'styled-components'

const Container = props => {
  return <div className={props.className}>
    {props.children}
  </div>
}

const StyledContainer = styled(Container)`
  margin: 0 auto;
  width: 480px;
  max-width: 96%;
`

export default StyledContainer
