import React from 'react'
import styled from 'styled-components'

import Checkbox from './Checkbox'


const Settings = props => {
  return <ul className={props.className}>
    <li>
      <div onClick={props.toggleShowAll} role="button">Reveal all objectives</div>
      <Checkbox checked={props.showAll} onClick={props.toggleShowAll} />
    </li>
  </ul>
}

const StyledSettings = styled(Settings)`
  display: flex;
  flex-direction: column;

  li {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 16px;

    > *:last-child {
      margin-left: 8px;
    }
  }
`

export default StyledSettings
