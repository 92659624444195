import React, { useState } from 'react'
import styled from 'styled-components'

const Accordion = props => {
  const [expand, setExpand] = useState(props.expandedByDefault);

  const handleKeyPress = event => {
    switch (event.keyCode) {
      case 13: // enter
      case 32: // space
        event.preventDefault()
        setExpand(!expand)
        break;
      case 40: // down
      case 39: // right
        event.preventDefault()
        setExpand(true)
        break;
      case 38: // up
      case 37: // left
        event.preventDefault()
        setExpand(false)
        break;
      default:
        break;
    }
  }
  
  return <div className={props.className + ' mb-4' + (expand ? ' expanded' : '')}>
    <div className="titleBar px-5 py-2" onClick={() => setExpand(!expand)} tabIndex="0" onKeyDown={handleKeyPress} role="button">
      {props.title}
    </div>
    <div className="body content mx-5 p-3 pt-2">{props.children}</div>
  </div>
}

const StyledAccordion = styled(Accordion)`
  color: var(--whiteText);
  
  .titleBar {
    background: var(--primaryBg);
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    position: relative;
    cursor: pointer;

    &:focus {
      box-shadow: var(--focusBoxShadow);
    }

    &:after {
      content: "";
      position: absolute;
      right: 18px;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid var(--whiteText);
      transition: 0.5s ease all;
    }
  }

  .body {
    display: none;
    background: var(--secondaryBg);
    border-radius: 0 0 16px 16px;
  }

  &.expanded {
    .titleBar:after {
      transform: translateY(calc(-50% - 2px)) rotate(-180deg);
    }
    .body {
      display: block;
    }
  }
`

export default StyledAccordion
