import React, { useState } from 'react'
import styled from 'styled-components'

import Button from './Button'
import Checkbox from './Checkbox'
import Modal from './Modal'
import Map from './Map'
import map from '../images/map.png'


const ObjectiveCard = props => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showLocation, setShowLocation] = useState(false);

  const handleLocationKeyPress = event => {
    if (
      event.keyCode === 13 || // enter
      event.keyCode === 32 // space
    ) {
      event.preventDefault()
      setShowLocation(true)
    }
  }

  const toggle = () => {
    if (!props.completed) {
      setShowSuccess(true)
    }
    props.toggle(props.objective)
  }

  return <div id={props.objective.handle} className={props.className + ' p-3 mb-3' + (props.available ? '' : ' card--locked')}>
    <div className="card__title fontHeading -mx-3 px-3 py-0.5 mb-3">
      {(props.objective.order ? props.objective.order + '. ' : '') + props.objective.title}
    </div>
    {props.objective.additional ? <div className="card__additional mb-2">{props.objective.additional}</div> : null}
    <div className="flex justify-end items-center">
      {props.completed ? <Button small={true} onClick={() => setShowSuccess(true)}>View success message</Button> : null}
      <div
        className="card__location actionBox ml-2 flex-shrink-0"
        onClick={() => setShowLocation(true)}
        tabIndex={props.available ? '0' : '-1'}
        onKeyDown={handleLocationKeyPress}
        role="button"
      />
      <Checkbox className="ml-2 flex-shrink-0" checked={props.completed} onClick={toggle} notTabbable={!props.available} />
    </div>

    <Modal
      isOpen={showSuccess}
      close={() => setShowSuccess(false)}
      objectiveTitle={props.objective.title}
      objectiveSubTitle={'Complete'}
      widthMode={props.objective.fullWidth ? 'fullWidth' : null}
    >
      {props.objective.success}
    </Modal>

    <Modal isOpen={showLocation} close={() => setShowLocation(false)} widthMode="fullContents">
      <Map
        x={props.objective.x}
        y={props.objective.y}
        locationIsStart={props.objective.locationIsStart}
        largeLocation={props.objective.largeLocation}
      />
    </Modal>
  </div>
}

const StyledObjectiveCard = styled(ObjectiveCard)`
  color: var(--whiteText);
  background: var(--primaryBg);
  border: 1px solid var(--whiteText);

  position: relative;
  &.card--locked {
    &:after {
      content: "? ? ?";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--solidBg);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24rem;
    }
  }

  .card__title {
    font-size: 24rem;
    background: var(--lightBg);
    font-style: italic;
    text-align: center;
  }

  .card__location {
    width: var(--checkboxSize);
    height: var(--checkboxSize);
    background: url(${map}) no-repeat center;
    background-size: cover;
    cursor: pointer;
  }

  .card__additional {
    > * + * {
      margin-top: var(--contentSpacing);
    }
    .dialogueBox {
      margin-top: 40px;
    }
  }
`

export default StyledObjectiveCard
