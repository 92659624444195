import React, { useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'

import { sideQuests } from '../objective-data'
import Container from './Container'
import InventoryPage from './InventoryPage'
import InventoryItem from './InventoryItem'
import Settings from './Settings'
import RestrictedShrines from './RestrictedShrines'
import ObjectiveList from './ObjectiveList'

import staminaVessels from '../images/inventory/stamina-vessels.png'
import climbingGear from '../images/inventory/climbing-gear.png'
import shieldSurfing from '../images/inventory/shield-surfing.png'
import remoteBombs from '../images/inventory/remote-bombs.png'
import magnesis from '../images/inventory/magnesis.png'
import stasis from '../images/inventory/stasis.png'
import cryonis from '../images/inventory/cryonis.png'
import bows from '../images/inventory/bows.png'
import bombArrows from '../images/inventory/bomb-arrows.png'
import fireAttacks from '../images/inventory/fire-attacks.png'
import iceAttacks from '../images/inventory/ice-attacks.png'
import electricAttacks from '../images/inventory/electric-attacks.png'
import korokLeaves from '../images/inventory/korok-leaves.png'
import boomerangs from '../images/inventory/boomerangs.png'
import hammers from '../images/inventory/hammers.png'
import axes from '../images/inventory/axes.png'


const Inventory = props => {
  const [showSettings, setShowSettings] = useState(false);
  const [doubleModal, setDoubleModal] = useState(false); // the ancestor of both modals sends some state down to the lower modal and the state updater down to the upper one

  const handleSettingsKeyPress = event => {
    if (
      event.keyCode === 13 || // enter
      event.keyCode === 32 // space
    ) {
      event.preventDefault()
      setShowSettings(true)
    } else if (
      event.keyCode === 27 // escape
    ) {
      setShowSettings(false)
    }
  }

  return <div className={props.className}>
    <Container>
      <div className="inventoryPages">
        <InventoryPage name="Restricted Shrines">
          <RestrictedShrines completed={props.completed} />
        </InventoryPage>
        <InventoryPage name="Side Quests" isInBackground={doubleModal}>
          <ObjectiveList
            compact={true}
            completed={props.completed}
            toggleObjective={props.toggleObjective}
            objectives={sideQuests}
            setDoubleModal={setDoubleModal}
          />
        </InventoryPage>
        <div className="inventoryPages__settings">
          <div
            className="inventoryPages__settingsIcon"
            onKeyDown={handleSettingsKeyPress}
            tabIndex="0"
            role="button"
          >
            <FontAwesomeIcon
              icon={faCog}
              onClick={() => setShowSettings(!showSettings)}
            />
            {showSettings ?
              <>
                <div className="inventoryPages__settingsTab">
                  <Settings toggleShowAll={props.toggleShowAll} showAll={props.showAll} />
                </div>
                <div
                  className="inventoryPages__settingsOverlay"
                  onClick={() => setShowSettings(false)}
                  onKeyDown={() => {}}
                  role="button"
                />
              </>
            : null}
          </div>
        </div>
      </div>
    </Container>

    <div className="inventoryList">
      <InventoryItem handle="climbingGear"    name="Climbing Gear"    colour="#8E4842" available={props.completed.hut}         image={climbingGear}
        lockedContent={<p>You cannot climb any surfaces except ladders, Sheikah tower walls and the Shrine of Ressuraction's exit.</p>}
        availableContent={<p>You can now climb freely, but <strong>cannot use stamina replenishment while climbing</strong>.</p>} />
      <InventoryItem handle="shieldSurfing"   name="Shield Surfing"   colour="#F4E0AF" available={props.completed.hawaKoth}    image={shieldSurfing}
        lockedContent={<p>You cannot <strong>shield surf</strong> or use <strong>sand seals</strong>.</p>}
        availableContent={<p>You can now <strong>shield surf</strong> and use <strong>sand seals</strong>.</p>} />
      <InventoryItem handle="staminaVessels"  name="Stamina Vessels"  colour="#54B940" available={props.completed.ketohWawai}  image={staminaVessels}
        lockedContent={<p>You cannot collect <strong>Stamina Vessels</strong> by any means.</p>}
        availableContent={<p>You can now collect <strong>Stamina Vessels</strong>.</p>} />
      <InventoryItem handle="remoteBombs"     name="Remote Bombs"     colour="#1DC1DB" available={props.completed.zeKasho}     image={remoteBombs}
        lockedContent={<p>You cannot use the <strong>Remote Bombs</strong> runes.</p>}
        availableContent={<p>You can now use the <strong>Remote Bombs</strong> runes.</p>} />
      <InventoryItem handle="magnesis"        name="Magnesis"         colour="#FE4A6B" available={props.completed.shoraHah}    image={magnesis}
        lockedContent={<p>You cannot use the <strong>Magnesis</strong> rune.</p>}
        availableContent={<p>You can now use the <strong>Magnesis</strong> rune.</p>} />
      <InventoryItem handle="stasis"          name="Stasis"           colour="#F7E35F" available={props.completed.mirroShaz}   image={stasis}
        lockedContent={<p>You cannot use the <strong>Stasis</strong> rune.</p>}
        availableContent={<p>You can now use the <strong>Stasis</strong> rune.</p>} />
      <InventoryItem handle="cryonis"         name="Cryonis"          colour="#9EF5FF" available={props.completed.neezYohma}   image={cryonis}
        lockedContent={<p>You cannot use the <strong>Cryonis</strong> rune.</p>}
        availableContent={<p>You can now use the <strong>Cryonis</strong> rune.</p>} />
      <InventoryItem handle="korokLeaves"     name="Korok Leaves"     colour="#4A6131" available={props.completed.kahOkeo}     image={korokLeaves}
        lockedContent={<p>You may collect but cannot use <strong>Korok Leaves</strong>.</p>}
        availableContent={<p>You can now use <strong>Korok Leaves</strong>.</p>} />
      <InventoryItem handle="hammers"         name="Hammers"          colour="#414849" available={props.completed.qazaTokki}   image={hammers}
        lockedContent={<p>You may collect but cannot use any weapon with <strong>"hammer"</strong> in its name.</p>}
        availableContent={<p>You can now use any weapon with <strong>"hammer"</strong> in its name.</p>} />
      <InventoryItem handle="axes"            name="Axes"             colour="#969792" available={props.completed.tuKaloh}     image={axes}
        lockedContent={<p>You may collect but cannot use any weapon with <strong>"axe"</strong> in its name.</p>}
        availableContent={<p>You can now use any weapon with <strong>"axe"</strong> in its name.</p>} />
      <InventoryItem handle="boomerangs"      name="Boomerangs"       colour="#B09559" available={props.completed.dilaMaag}    image={boomerangs}
        lockedContent={<p>You may collect but cannot use any weapon with <strong>"boomerang"</strong> in its name.</p>}
        availableContent={<p>You can now use any weapon with <strong>"boomerang"</strong> in its name.</p>} />
      <InventoryItem handle="bows"            name="Bows"             colour="#724F31" available={props.completed.kahYah}      image={bows}
        lockedContent={<p>You may collect but cannot use <strong>bows</strong> and <strong>arrows</strong>.</p>}
        availableContent={<p>You can now use <strong>bows</strong> and <strong>arrows</strong>.</p>} />
      <InventoryItem handle="bombArrows"      name="Bomb Arrows"      colour="#C6352B" available={props.completed.rokUwog}     image={bombArrows}
        lockedContent={<p>You may collect but cannot use <strong>Bomb Arrows</strong>.</p>}
        availableContent={<p>You can now use <strong>Bomb Arrows</strong>.</p>} />
      <InventoryItem handle="fireAttacks"     name="Fire Attacks"     colour="#F57109" available={props.completed.tutsuwaNima} image={fireAttacks}
        lockedContent={<><p>You may collect but cannot use <strong>Fire Arrows</strong>, <strong>Fire/Meteor Rods</strong>, or any weapon with <strong>"flame"</strong> in its name. You may use Torches and may light standard Arrows and wooden weapons on fire.</p><p>You cannot use <strong>Flint and Wood</strong> to create a flame on cue, but you may use Flint to set existing things (e.g. a cooking pot) alight.</p></>}
        availableContent={<><p>You can now use <strong>Fire Arrows</strong>, <strong>Fire/Meteor Rods</strong>, and any weapon with <strong>"flame"</strong> in its name.</p><p>You can also now use <strong>Flint and Wood</strong> to create a flame on cue.</p></>} />
      <InventoryItem handle="iceAttacks"      name="Ice Attacks"      colour="#DEEFF9" available={props.completed.jitanSami}   image={iceAttacks}
        lockedContent={<p>You may collect but cannot use <strong>Ice Arrows</strong>, <strong>Ice/Blizzard Rods</strong>, or any weapon with <strong>"frost"</strong> in its name.</p>}
        availableContent={<p>You can now use <strong>Ice Arrows</strong>, <strong>Ice/Blizzard Rods</strong>, and any weapon with <strong>"frost"</strong> in its name.</p>} />
      <InventoryItem handle="electricAttacks" name="Electric Attacks" colour="#E4F962" available={props.completed.shaeKatha}   image={electricAttacks}
        lockedContent={<p>You may collect but cannot use <strong>Shock Arrows</strong>, <strong>Lightning/Thunderstorm Rods</strong>, or any weapon with <strong>"thunder"</strong> in its name.</p>}
        availableContent={<p>You can now use <strong>Shock Arrows</strong>, <strong>Lightning/Thunderstorm Rods</strong>, and any weapon with <strong>"thunder"</strong> in its name.</p>} />
    </div>
  </div>
}

const StyledInventory = styled(Inventory)`
  --paddingX: 24px;
  --paddingXDesktop: 40px;
  --paddingY: 4px;

  position: sticky;
  bottom: 0;
  background: var(--primaryBg);
  border-top: 1px solid var(--whiteText);
  color: var(--whiteText);
  z-index: 2;

  .inventoryPages {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: var(--paddingY) var(--paddingX);
    @media screen and (min-width: 480px) {
      padding-left: var(--paddingXDesktop);
      padding-right: var(--paddingXDesktop);
    }
    > * {
      margin: 4px;
    }
  }

  .inventoryPages__settings {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: calc(var(--paddingY) * -1);
    width: var(--paddingX);
    height: 100%;
    z-index: 3;
    @media screen and (min-width: 480px) {
      width: var(--paddingXDesktop);
    }

    .inventoryPages__settingsIcon {
      position: relative;
      margin-left: auto;
      width: 20px;
      height: 20px;
      @media screen and (min-width: 480px) {
        width: 24px;
        height: 24px;
      }
      > svg {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 2;
        transition: 0.3s ease all;
        cursor: pointer;
      }
      &:focus > svg {
        color: var(--blueText);
      }

      .inventoryPages__settingsTab {
        position: absolute;
        right: -8px;
        bottom: -8px;
        width: 92vw;
        max-width: 488px;
        border-radius: 4px 4px 16px 4px;
        background: var(--primaryBg);
        border: 1px solid var(--whiteText);
        z-index: 1;
        padding: 16px 16px calc(16px + 100%);
      }

      .inventoryPages__settingsOverlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--overlay);
      }
    }
  }

  .inventoryList {
    --gap: 8px;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    column-gap: var(--gap);
    row-gap: var(--gap);
    margin: 0 auto;
    padding: 0 var(--gap) var(--gap);
    max-width: 640px;

    @media screen and (max-width: 439px) {
      max-width: 320px;
      grid-template-columns: repeat(6, 1fr);
      .remoteBombs      {grid-row-start: 1; grid-column-start: 2;}
      .magnesis         {grid-row-start: 1; grid-column-start: 3;}
      .stasis           {grid-row-start: 1; grid-column-start: 4;}
      .cryonis          {grid-row-start: 1; grid-column-start: 5;}
      .korokLeaves      {grid-row-start: 3; grid-column-start: 1;}
      .hammers          {grid-row-start: 3; grid-column-start: 2;}
      .axes             {grid-row-start: 3; grid-column-start: 3;}
      .boomerangs       {grid-row-start: 3; grid-column-start: 4;}
      .bows             {grid-row-start: 3; grid-column-start: 5;}
      .bombArrows       {grid-row-start: 3; grid-column-start: 6;}
      .climbingGear     {grid-row-start: 2; grid-column-start: 1;}
      .shieldSurfing    {grid-row-start: 2; grid-column-start: 2;}
      .staminaVessels   {grid-row-start: 2; grid-column-start: 3;}
      .fireAttacks      {grid-row-start: 2; grid-column-start: 4;}
      .iceAttacks       {grid-row-start: 2; grid-column-start: 5;}
      .electricAttacks  {grid-row-start: 2; grid-column-start: 6;}
    }
    @media screen and (max-width: 559px) {
      --gap: 4px;
    }

    @media screen and (min-width: 440px) and (max-width: 1199px) {
      .climbingGear     {grid-row-start: 1; grid-column-start: 1;}
      .shieldSurfing    {grid-row-start: 1; grid-column-start: 2;}
      .remoteBombs      {grid-row-start: 1; grid-column-start: 3;}
      .magnesis         {grid-row-start: 1; grid-column-start: 4;}
      .stasis           {grid-row-start: 1; grid-column-start: 5;}
      .cryonis          {grid-row-start: 1; grid-column-start: 6;}
      .fireAttacks      {grid-row-start: 1; grid-column-start: 7;}
      .iceAttacks       {grid-row-start: 1; grid-column-start: 8;}
      .staminaVessels   {grid-row-start: 2; grid-column-start: 1;}
      .korokLeaves      {grid-row-start: 2; grid-column-start: 2;}
      .hammers          {grid-row-start: 2; grid-column-start: 3;}
      .axes             {grid-row-start: 2; grid-column-start: 4;}
      .boomerangs       {grid-row-start: 2; grid-column-start: 5;}
      .bows             {grid-row-start: 2; grid-column-start: 6;}
      .bombArrows       {grid-row-start: 2; grid-column-start: 7;}
      .electricAttacks  {grid-row-start: 2; grid-column-start: 8;}
    }
    @media screen and (min-width: 1200px) {
      grid-template-columns: repeat(16, 1fr);
      max-width: none;
    }
  }
`

export default StyledInventory
