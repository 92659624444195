import React from 'react'
import styled from 'styled-components'

const Button = props => {
  return <button type="button" className={props.className} onClick={props.onClick}>
    {props.children}
  </button>
}

const StyledButton = styled(Button)`
  padding: ${props => props.small ? '3px 12px' : '6px 16px'};
  border: 1px solid currentColor;
  border-radius: 1000px;
  font-size: ${props => props.small ? '12rem' : '14rem'};
  transition: 0.3s ease background-color;

  &:hover, &:focus {
    background-color: var(--secondaryBg);
  }
  &:focus {
    box-shadow: var(--focusBoxShadow);
  }

  @media screen and (min-width: 480px) {
    padding: ${props => props.small ? '6px 16px' : '12px 32px'};
  }
`

export default StyledButton
