import React from 'react'
import styled from 'styled-components'

import Accordion from './Accordion'


const Restrictions = props => {
  return <div className={props.className}>
    <Accordion title="Restrictions" expandedByDefault={props.expandedByDefault}>
      <p>When playing this quest, you cannot:</p>
      <ul className="bullets spaced">
        <li>Enter a shrine on the <em>Restricted Shrines</em> list or a Divine Beast until told to do so by your next objective.</li>
        <li>Enter Hyrule Castle until all four Divine Beasts have been freed.</li>
        <li>Use items that replenish stamina while climbing, paragliding or swimming (you may overfill your stamina beforehand).*</li>
        <li>Destroy ore deposits with anything other than a <strong>hammer</strong>.*</li>
        <li>Cut down trees with anything other than an <strong>axe</strong>.*</li>
        <li>Throw weapons that are not <strong>boomerangs</strong> (you can still 'throw' weapons that don't leave Link's hand, such as the Master Sword and Korok Leaves).*</li>
        <li>Use glitches.*</li>
        <li>Do any of the following until the ability is unlocked:<ul className="bullets">
          <li>Climb (except for ladders, Sheikah tower walls and the Shrine of Ressuraction's exit)</li>
          <li>Shield surf (including using sand seals)</li>
          <li>Obtain Stamina Vessels</li>
        </ul></li>
        <li>Use any of the following until they are unlocked:<ul className="bullets">
          <li>Remote Bombs</li>
          <li>Magnesis</li>
          <li>Stasis</li>
          <li>Cryonis</li>
          <li>Korok Leaves</li>
          <li>Hammer weapons</li>
          <li>Axe weapons</li>
          <li>Boomerang weapons</li>
          <li>Bows</li>
          <li>Bomb Arrows</li>
          <li>Fire Arrows, fire-powered weapons, or Flint and Wood together</li>
          <li>Ice Arrows or ice-powered weapons</li>
          <li>Shock Arrows or electricity-powered weapons</li>
        </ul></li>
      </ul>
      <p>The use of DLC and amiibo are allowed, so long as none of the restrictions above are broken.</p>
      <p>* <small>These rules don't affect the overall quest directly. Feel free to ignore them if they don't work for you.</small></p>
    </Accordion>
  </div>
}

const StyledRestrictions = styled(Restrictions)`
`

export default StyledRestrictions
